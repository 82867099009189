import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import Select from "@sm/components/custom/Select";
import Table from '@sm/components/custom/Table';
import Toggler from '@sm/components/custom/Toggler';

import '@assets/css/clients.css';
import loadingIcon from '@assets/images/loading.png';
import PSPIconSingle from '@assets/images/psp_single_wl.png';

import { crudActions } from "@sm/services/crudActions";
import { FETCH_PERMISSIONS } from '@sm/actions/types';
const store =  require('@sm/reducers/index');

class PSPs extends Component {
  state = {
    psps: [],
    companies: [],
    selectedCompany: "",
    tempCompany: "",
    searchPattern: "",
    access: [],
    isSuggestionBoxOpen: false,
    roleId: "",
    showProviders: false,
    pspStatusLoader: [],
    showAlert: false,
    alertType: "error",
    alertMessage: "",
    isDropDownOpen: false,
    openDropdownId: null,

    columns: [{
      value: "name",
      label: "Provider Name"
    },{
      value: "id",
      label: "API"
    }, store.default.getState().authReducer.roleId === "MASTER_TECH" && {
      value: "shortName",
      label: "Provider Short Name"
    }, {
      value: "companyIds",
      label: "White Label"
    }, {
      value: "active",
      label: "Active / Inactive"
    },{
      value: "id",
      label: "View",
      centerAligned: true,
      className: "btn view-client",
      route: "/view-provider",
      isAction: true
    }]
  };

  subscribeFunction = null;

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      const roleId = storeState.roleId;
      this.setState({
        access: storeState.access,
        roleId,
        showProviders: roleId === "WL_ADMIN" || roleId === "WL_USER"
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        const roleId = storeState.roleId;
        this.setState({
          access: state.access,
          roleId,
          showProviders: roleId === "WL_ADMIN" || roleId === "WL_USER"
        });
      }
    });

    crudActions.get("v1/companies/labels").then(companies => {
      if (companies) {
        this.setState({
          companies: companies,
          tempCompany: companies[0].value,
          ...(companies.length === 1 && { selectedCompany: companies[0].value })
        }, () => {
          if (companies.length === 1) {
            this.onUpdateClick();
          }
        });
      }
    });
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  onSuggestionClick(pspName) {
    this.setState({
      searchPattern: pspName,
      isSuggestionBoxOpen: false
    });
  };

  searchSuggests(isSuggestion) {
    const { psps, searchPattern, selectedCompany } = this.state;

    let sortedProviders = psps;
    if (selectedCompany) {
      sortedProviders = psps.filter(psp => psp.companyIds.includes(selectedCompany));
    }

    const searchValue = searchPattern.toLowerCase();
    if (!searchValue) {
      return isSuggestion ? [] : this.prepareTableData(sortedProviders);
    }

    sortedProviders = sortedProviders.filter(psp => {
      return psp.name.toLowerCase().includes(searchValue) || psp.shortName.toLowerCase().includes(searchValue) || psp.id.toString().includes(searchValue);
    });

    return this.prepareTableData(sortedProviders);
  };

  prepareTableData = (sortedProviders) => {
    const { companies, pspStatusLoader, selectedCompany } = this.state;
    return sortedProviders.map(provider => {
      const displayedItems = provider.currencyAndMethods.length > 0
        ? provider.currencyAndMethods.slice(0, 2)
          .map(currencyMethod => currencyMethod.currency)
          .join(', ')
        : 'Select...';

      return {
        ...provider,
        companyIds: companies.find(company => company.value === selectedCompany)?.label || '',
        active: provider.active ? "Active" : "Inactive",
        enabled: <div className="dropdown">
          <button
            onClick={ (event) => {
              this.toggleDropdown(provider.id);
            } }
            className="dropdown-btn">
            {displayedItems} {provider.currencyAndMethods.length > 2 ? '...' : ''}
          </button>
          {this.state.openDropdownId === provider.id && (
            <div className="dropdown-content">
              {provider.currencyAndMethods.length === 0 ? (
                <div className="dropdown-item no-options">No options</div>
              ) : (
                provider.currencyAndMethods.map((currencyAndMethod, index) => {
                  const {currency, method, enabled} = currencyAndMethod;
                  return (
                    <label key={ index } className="dropdown-item" onClick={ (event) => this.onTogglerClick(currencyAndMethod, provider.id, !enabled) }>
                      <span>{ currency } + { method }</span>
                      <div className="toggle-switch">
                        { pspStatusLoader.some((item) => item.psId === provider.id && item.currency === currency && item.method === method) ?
                          <div className="loading-wrapper">
                            <img src={ loadingIcon } alt="loading" style={ { height: "25px" } }/>
                          </div>
                          : <Toggler
                            active={ enabled }
                            disabled={ false }
                            value={ { currencyAndMethod } }
                          />
                        }
                      </div>
                    </label>
                  );
                })
              )}
            </div>
          )}
        </div>
      };
    });
  };

  isBoxOpen() {
    if (this.state.isSuggestionBoxOpen) {
      return <div
        className='search-suggests'>
        {
          this.searchSuggests(true).map((psp, index) => {
            return <div className='suggestion' key={ psp.shortName + index }
              onClick={ () => this.onSuggestionClick(psp.name) }>
              { psp.name }
            </div>;
          })
        }
      </div>;
    }

    return <div></div>;
  };

  handleSearchChange = (e) => {
    const value = e.target.value;
    this.setState({
      searchPattern: value,
      isSuggestionBoxOpen: false
    });
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);

    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  addNewColumn(columns) {
    // eslint-disable-next-line eqeqeq
    if (columns.find((elem) => elem.label == "Edit")) {
      return columns;
    }
    columns.push({
      value: "id",
      label: "Edit",
      centerAligned: true,
      className: "btn edit-client",
      route: "/edit-provider",
      isAction: true
    });
    return columns;
  };

  onCompanyChange = (event) => {
    this.setState({
      tempCompany: event.value
    });
  };

  onUpdateClick = () => {
    const { tempCompany } = this.state;
    if (tempCompany) {
      this.setState({
        selectedCompany: tempCompany,
        showProviders: true
      }, () => {
        const { columns, selectedCompany } = this.state;

        Promise.all([
          crudActions.get(`v1/psp?companyId=${selectedCompany}`),
          crudActions.get(`v1/companies/provider-toggle/${selectedCompany}`)
        ])
          .then((data) => {
            const psps = data[0];
            const toggleInfo = data[1];

            this.setState({
              showProviders: true,
              psps
            });

            const isStatusColumnPresent = columns.some((elem) => elem.value === "enabled");
            if (toggleInfo.providerOnOffToggleVisible && this.checkPageAccess("PSP_ON_OFF")) {
              if (!isStatusColumnPresent) {
                columns.splice(4, 0, { value: "enabled", label: "Status by currency" });
              }
            } else {
              if (isStatusColumnPresent) {
                columns.splice(4, 1);
              }
            }
          });
      });
    }
  };

  onTogglerClick = (option, providerId, value) => {
    const { pspStatusLoader, selectedCompany } = this.state;

    if(pspStatusLoader.some((item) => item.psId === providerId && item.currency === option.currency && item.method === option.method)) {
      return;
    }

    if (providerId) {
      this.setState({
        pspStatusLoader: pspStatusLoader.concat({ psId : providerId, currency : option.currency, method: option.method })
      });

      crudActions.patch(`v1/psp`, {
        enabled: value,
        id: providerId,
        companyId: selectedCompany,
        currency: option.currency,
        method: option.method,
      }).then(() => {
        const { psps } = this.state;
        const currentPsp = psps.find(psp => psp.id === providerId);
        const defaultValue = currentPsp.currencyAndMethods.find((current) => current.currency === option.currency && current.method === 'DEFAULT');
        currentPsp.currencyAndMethods = currentPsp.currencyAndMethods.map((currencyAndMethod) => {
          return currencyAndMethod.currency === option.currency && (currencyAndMethod.method === option.method || option.method === 'DEFAULT')
            ? { ...currencyAndMethod, enabled: option.method !== 'DEFAULT' ? !currencyAndMethod.enabled : !defaultValue.enabled }
            : currencyAndMethod;
        });
        this.setState({
          psps,
          pspStatusLoader: this.state.pspStatusLoader.filter(status => status.psId !== providerId && status.currency !== option.currency && status.method !== option.method),
        });
      }).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              pspStatusLoader: this.state.pspStatusLoader.filter(status => status.psId !== providerId && status.currency !== option.currency && status.method !== option.method)
            });
          }
        }
      );
    }
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  handleClickOutside = (event) => {
    if (this.state.openDropdownId && !event.target.closest('.dropdown')) {
      this.closeDropdown();
    }
  };

  closeDropdown = () => {
    this.setState({ openDropdownId: null });
  };

  toggleDropdown = (providerId) => {
    this.setState((prevState) => ({
      openDropdownId: prevState.openDropdownId === providerId ? null : providerId
    }));
  };

  render() {
    const {
      alertMessage,
      alertType,
      columns,
      companies,
      roleId,
      searchPattern,
      showAlert,
      showProviders,
      tempCompany
    } = this.state;

    if (this.checkPageAccess("PSP_EDIT")) {
      this.addNewColumn(columns);
    }
    return (
      <Row flexGrow={ 1 } className="module apidata clients" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ PSPIconSingle } alt="" style={ { marginRight: 10 } } />
                Providers
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                {store.default.getState().authReducer.roleId === "MASTER_TECH" && (
                  <Column horizontal='end' style={ { paddingRight: 15 } }>
                    <Row horizontal='end' vertical='center' style={ { color: '#ccc', fontSize: '12px' } }>
                      <NavLink to={ `/add-provider` } className="btn add-merchant-button">
                        ADD PROVIDER
                      </NavLink>
                    </Row>
                  </Column>
                )}
              </Row>
            </Column>
          </Row>

          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              { roleId !== "WL_ADMIN" && roleId !== "WL_USER" && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      SEARCH PROVIDERS
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                        <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> White Label </label>
                            <Select
                              name="company"
                              value={ tempCompany || '' }
                              required={ true }
                              clearable={ false }
                              onChange={ (value) => this.onCompanyChange(value) }
                              options={ companies }
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <span/>
                          </Column>
                        </Row>
                        <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <span/>
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' className="input-column">
                            <CustomButton
                              title="Update"
                              type="submit"
                              onClick={ () => this.onUpdateClick() }
                            />
                          </Column>
                        </Row>
                      </Row>
                    </div>
                  </Panel.Body>
                </Panel>
              )}
              
              { showProviders && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      PROVIDERS INFORMATION
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content" style={ { overflow: 'unset' } }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' className='search-bar'>
                        <input
                          className='form-control'
                          type='text'
                          value={ searchPattern }
                          placeholder="Search Provider"
                          onChange={ this.handleSearchChange }
                        />
                        { this.isBoxOpen() }
                      </Row>

                      <div className="clients-wrapper">
                        <Table
                          columns={ columns }
                          data={ this.searchSuggests() }
                          isStriped={ true }
                          defaultSortBy={ columns[0].value }
                        />
                      </div>
                    </div>
                  </Panel.Body>
                </Panel>
              )}

            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>
    );
  }
}

export default PSPs;
