import React, { Component } from 'react';
import { Row, Column } from 'simple-flexbox';
import { Panel } from "react-bootstrap";

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import PageHeader from "@sm/components/PageHeader";
import Form from "@sm/core/Form";
import Multiselect from '@sm/components/custom/customMultiselect';
import PromptModal from '@modules/generateReport/PromptModal';
import Select from "@sm/components/custom/Select";
import Spinner from '@sm/components/Spinner';
import Toggler from '@sm/components/custom/Toggler';

import '@assets/css/pspCascading.css';
import pspPriorityIconSingle from '@assets/images/psp-cascading_single_wl.png';
import loadingIcon from '@assets/images/loading.png';

import icons from '@sm/components/icons/methods';
import { crudActions } from "@sm/services/crudActions";
import { FETCH_PERMISSIONS } from '@sm/actions/types';
import { addLogItem } from "@sm/utils/utils";
const store =  require('@sm/reducers/index');

class PayoutCascading extends Component {
  state = {
    payoutMethods: [],
    countries: [],
    currencies: [],
    companies: [],

    lookups: {},
    companiesData: {},

    midsPriorities: {},
    errorsMap: {},

    controls: new Form({
      payoutMethods: [],
      country: "",
      whiteLabel: "",
      merchantId: "",
      currency: ""
    }, [{
      name: "payoutMethods",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "country",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "currency",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "whiteLabel",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "merchantId",
      type: "isString",
      rules: {
        required: true
      }
    }]),
    savedData: {},
    cascadingMode: "",
    savedCascadingMode: "",

    companiesClients: {},
    companiesAccounts: {},

    prioritize: "manual",

    loadingLookups: true,
    submitTouched: false,
    saveTouched: false,
    loadingSettings: false,
    showPromptModal: false,
    resetCascadingLoading: false,

    access: [],
    toggleLoader: [],
    showAlert: false,
    alertType: "success",
    alertMessage: "",
    logs: []
  };

  subscribeFunction = null;

  componentDidMount() {
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    Promise.all([
      crudActions.get('v1/adminpanel/lookups'),
      crudActions.get("v1/companies/clients")
    ]).then(
      (data) => {
        const lookups = data[0];
        const companiesData = data[1];
        if (lookups && companiesData) {
          const companiesList = companiesData.map(elem => elem.company);
          companiesList.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));
          this.setState({
            loadingLookups: false,
            lookups: lookups,
            companiesData: companiesData,
            companies: companiesList,
            companiesClients: companiesData.reduce((accumulator, currentElem) => {
              accumulator[currentElem.company.value] = currentElem.clients.map(elem => elem.client);
              return accumulator;
            }, {}),
            companiesAccounts: companiesData.reduce((accumulator, currentElem) => {
              accumulator[currentElem.company.value] = currentElem.accounts;
              return accumulator;
            }, {}),
          }, () => {
            const { companiesClients, companies, controls } = this.state;
            const firstCompany = companies[0].value;
            if (firstCompany) {
              const firstMerchant = companiesClients[firstCompany][0];
              const merchantId = firstMerchant ? firstMerchant.value : "";
              
              this.setState({
                controls: Object.assign(controls, {
                  whiteLabel: firstCompany,
                  merchantId,
                  currency: "",
                  country: "default"
                })
              }, () => this.loadCompanyData(firstCompany));
            }
          });
        }
      }
    );
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  mapMethods = () => {
    const { controls, payoutMethods } = this.state;
    const field = controls["payoutMethods"];
    return field.map(elem => {
      return payoutMethods.find(lookupElem => elem === lookupElem.value);
    });
  };

  onValueChange = (newValue, fieldName) => {
    let { controls } = this.state;
    const { companiesClients, submitTouched } = this.state;
    if (newValue.value) {
      controls = Object.assign(controls, {
        [fieldName]: newValue.value
      });
    } else if (newValue.target) {
      controls = Object.assign(controls, {
        [fieldName]: newValue.target.value
      });
    } else {
      controls = Object.assign(controls, {
        [fieldName]: newValue.map(elem => elem.value)
      });
    }

    if (fieldName === "whiteLabel") {
      this.loadCompanyData(controls.whiteLabel);
      const firstMerchant = companiesClients[controls.whiteLabel][0];
      controls.merchantId = firstMerchant ? firstMerchant.value : "";
      const merchantCurrencies = this.getUniqueItems(controls.whiteLabel, controls.merchantId, "currency");
      if (merchantCurrencies.length === 1) {
        controls.currency = merchantCurrencies[0].value;
        const merchantMethods = this.getUniqueItems(controls.whiteLabel, controls.merchantId, "payoutMethods");
        controls.payoutMethods = merchantMethods.map(elem => elem.value);
      } else {
        controls.currency = "";
        controls.payoutMethods = [];
      }
    }

    if (fieldName === "merchantId") {
      const merchantCurrencies = this.getUniqueItems(controls.whiteLabel, controls.merchantId, "currency");
      if (merchantCurrencies.length === 1) {
        controls.currency = merchantCurrencies[0].value;
        const merchantMethods = this.getUniqueItems(controls.whiteLabel, controls.merchantId, "payoutMethods");
        controls.payoutMethods = merchantMethods.map(elem => elem.value);
      } else {
        controls.currency = "";
        controls.payoutMethods = [];
      }
    }

    if (fieldName === "currency") {
      if (controls.currency) {
        const merchantMethods = this.getUniqueItems(controls.whiteLabel, controls.merchantId, "payoutMethods");
        controls.payoutMethods = merchantMethods.map(elem => elem.value);
      } else {
        controls.payoutMethods = [];
      }
    }

    if (submitTouched) {
      controls.isFormValid();
    }

    this.setState({
      controls,
      submitTouched: false
    });
  };

  mapItems = (fieldValue, arrayName) => {
    if (!arrayName) {
      return {
        label: fieldValue
      };
    }

    const { lookups } = this.state;
    const foundArray = lookups[arrayName];

    if (!foundArray) {
      return {
        label: ""
      };
    }

    const item = foundArray.find(elem => elem.value === fieldValue);

    return item;
  };

  getUniqueItems = (wl, merchantId, type) => {
    const { companiesData, controls, currencies, payoutMethods } = this.state;
    let items = currencies;
    if (type === "payoutMethods") {
      items = payoutMethods;
    }
    const selectedCompany = companiesData.find(elem => elem.company.value === wl);
    if (selectedCompany) {
      const selectedCompanyClient = selectedCompany.clients.find(elem => elem.client.value === merchantId);
      if (selectedCompanyClient) {
        const selectedCompanyClientMids = selectedCompanyClient.payoutMids;
        if (selectedCompanyClientMids.length) {
          const property = type === "currency" ? "currency" : "method";
          let filteredMids = selectedCompanyClientMids;
          if (type === "payoutMethods") {
            filteredMids = selectedCompanyClientMids.filter(elem => elem.currency === controls.currency);
          }
          const allItems = filteredMids.map(elem => elem[property]);
          const uniqueItems = [...new Set(allItems)];
          return items.filter(elem => uniqueItems.indexOf(elem.value) > -1 || uniqueItems.indexOf(elem.label) > -1);
        } else {
          return [];
        }
      }
    }
    return items;
  };

  getFilteredPayoutMethods = () => {
    const { controls, payoutMethods } = this.state;

    const merchantMethods = this.getUniqueItems(controls.whiteLabel, controls.merchantId, "payoutMethods");
    return payoutMethods.filter(method => merchantMethods.some(mm => mm.value === method.value));
  };

  loadCompanyData = (whiteLabelId) => {
    const { controls } = this.state;
    crudActions.get(`v1/adminpanel/company/${whiteLabelId}`).then(
      (data) => {
        if (data) {
          const countries = (data.storedLookups.country || []).map(elem => this.mapItems(elem, "country"));
          countries.unshift({
            label: "Default",
            value: "default",
            prevent_sorting: true
          });
          const payoutMethods = (data.storedLookups.payoutMethods || []).map(elem => this.mapItems(elem, "payoutMethods"));
          const currencies = (data.storedLookups.currency || []).map(elem => this.mapItems(elem, "currency"));

          let methods = payoutMethods.map(elem => elem.value);

          if (controls.merchantId) {
            const merchantMethods = this.getUniqueItems(whiteLabelId, controls.merchantId, "payoutMethods");
            methods = merchantMethods.map(elem => elem.value);
          }

          this.setState({
            countries,
            currencies,
            payoutMethods,
            cascadingMode: data.storedLookups.cascadingMode,
            controls: Object.assign(this.state.controls, {
              payoutMethods: methods,
              country: "default"
            })
          }, () => {
            const merchantCurrencies = this.getUniqueItems(controls.whiteLabel, controls.merchantId, "currency");
            if (merchantCurrencies.length === 1) {
              this.setState({
                controls: Object.assign(this.state.controls, {
                  currency: merchantCurrencies[0].value
                })
              });
            }
          });
        }
      }
    );
  };

  onUpdateClick = () => {
    const { controls, prioritize, cascadingMode } = this.state;
    const areControlsValid = controls.isFormValid();
    this.setState({
      controls,
      submitTouched: true,
      showPanels: areControlsValid,
      loadingSettings: areControlsValid,
      savedCascadingMode: cascadingMode,
      logs: [],
      savedData: { ...controls }
    });

    if (areControlsValid) {
      crudActions.get("v1/companies/clients")
        .then(data => {
          if (data) {
            const companiesList = data.map(elem => elem.company);
            companiesList.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));
            this.setState({
              companiesData: data,
              companies: companiesList,
              companiesClients: data.reduce((accumulator, currentElem) => {
                accumulator[currentElem.company.value] = currentElem.clients.map(elem => elem.client);
                return accumulator;
              }, {}),
              companiesAccounts: data.reduce((accumulator, currentElem) => {
                accumulator[currentElem.company.value] = currentElem.accounts;
                return accumulator;
              }, {}),
              submitTouched: false
            });
          }
        })
        .catch(
          err => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message
              });
            }
          }
        );
      crudActions.post(`v1/psp/priorities/payout`, controls.data()).then(
        (data) => {
          if (data) {
            const midsPriorities = data.midsPriorities;
            Object.keys(midsPriorities).forEach((method) => {
              midsPriorities[method] = midsPriorities[method].map((elem, index) => {
                return Object.assign(elem, {
                  priority: elem.priority || ""
                });
              });
            });

            this.setState({
              midsPriorities,
              showPanels: true,
              loadingSettings: false
            }, () => this.handlePrioritizeChange(prioritize));
          }
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              showPanels: false,
              loadingSettings: false
            });
          }
        }
      );
    }
  };

  handlePrioritizeChange = (value) => {
    const { midsPriorities } = this.state;

    const sortMids = (property, toReverse) => {
      Object.keys(midsPriorities).forEach(method => {
        midsPriorities[method].sort((elemA, elemB) => {
          const firstElemProp = elemA[property];
          const secondElemProp= elemB[property];
          if (firstElemProp < secondElemProp) {
            return -1;
          } else if (firstElemProp > secondElemProp || !firstElemProp || !secondElemProp) {
            return 1;
          }

          return 0;
        });

        if (toReverse) {
          midsPriorities[method].reverse();
        }

        if (property !== "priority") {
          midsPriorities[method].forEach((elem, index) => {
            if (elem.totalLoad !== elem.maxDailyLoad) {
              elem.priority = index + 1;
            }
          });
        }
      });
    };

    if (value === "manual") {
      sortMids("priority");
    }

    this.setState({
      midsPriorities,
      prioritize: value
    }, () => this.errorsCheck());
  };

  prioritiesList = (list) => {
    const maxPriority = Math.max(...list.map(mid => mid.priority));
    const prioritiesLength = maxPriority > list.length ? maxPriority : list.length;
    const priorities = [];
    for (let i = 1; i <= prioritiesLength; i++) {
      priorities.push({
        value: i,
        label: i.toString()
      });
    }

    return priorities;
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  onPriorityValueChange = (value, settingName, midId) => {
    const { midsPriorities, prioritize } = this.state;
    const setting = midsPriorities[settingName];
    const mid = setting.find(elem => elem.midId === midId);
    const prevValue = mid.priority;
    mid.priority = value.value;

    if (prevValue !== value.value) {
      this.addLog(prevValue, value.value, "priority_" + midId, midId);
    }

    this.setState({
      midsPriorities
    }, () => {
      this.handlePrioritizeChange(prioritize);
    });
  };

  addLog = (valueBefore, valueAfter, field, id) => {
    const { logs } = this.state;

    const type = "CASCADING_PAYOUT_UPDATE";

    const updatedLogs = addLogItem(logs, type, valueBefore, valueAfter, field, id);

    this.setState({
      logs: updatedLogs
    });
  };

  onTogglerClick = (value, settingName, midId) => {
    const { midsPriorities, toggleLoader, savedData } = this.state;
    if (midId) {
      toggleLoader.push(midId);
      this.setState({
        toggleLoader
      });

      const setting = midsPriorities[settingName];
      const mid = setting.find(elem => elem.midId === midId);

      const changes = addLogItem(
        [],
        "MID_UPDATE_CASCADING_STATUS_PAYOUT",
        mid.midEnabled ? "on" : "off",
        value ? "on" : "off",
        "enabled",
        midId
      );

      crudActions.patch(`v1/mids/update-availability`, {
        enabled: value,
        type: "payout",
        id: midId,
        changes,
        companyId: savedData.whiteLabel
      }).then(() => {
        mid.midEnabled = value;

        this.setState({
          midsPriorities,
          toggleLoader: this.state.toggleLoader.filter(i => i !== midId)
        });
      }).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              toggleLoader: this.state.toggleLoader.filter(i => i !== midId)
            });
          }
        }
      );
    }
  };

  getLoadStatus = (totalLoad, maxDailyLoad) => {
    if (!totalLoad || !maxDailyLoad) {
      return 0;
    } else if (totalLoad > maxDailyLoad) {
      return 100;
    }

    return Math.round((totalLoad / maxDailyLoad) * 100);
  };

  errorsCheck = () => {
    const { midsPriorities, errorsMap } = this.state;

    let isErrors = false;
    Object.keys(midsPriorities).forEach(method => {
      const mids = midsPriorities[method];
      mids.forEach(mid => {
        if (mid.accountEnabled) {
          const error = !mid.priority;
          errorsMap[mid.midId] = error;
          if (error) {
            isErrors = error;
          }
        }
      });
    });

    this.setState({
      errorsMap
    });

    return isErrors;
  };

  onSavePriorities = () => {
    const { midsPriorities, logs, savedData } = this.state;

    const isErrors = this.errorsCheck();
    this.setState({
      saveTouched: true
    });

    if (!isErrors) {
      const combinedMids = Object.keys(midsPriorities).reduce((accumulator, currentElem) => {
        return accumulator.concat(midsPriorities[currentElem]);
      }, []);

      // temp fix, needs to be fixed in future
      const filteredMids = combinedMids.filter(mid => mid.accountEnabled && mid.priority);

      const data = { priorities: filteredMids, changes: logs, companyId: savedData.whiteLabel };

      crudActions.post(`v1/psp/priorities/payout/set`, data).then(
        () => {
          this.setState({
            showAlert: true,
            alertType: "success",
            alertMessage: "Payout priorities successfully updated.",
            logs: []
          });
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }
        }
      );
    } else {
      this.setState({
        showAlert: true,
        alertType: "error",
        alertMessage: "Some priorities are invalid, please, fix them."
      });
    }
  };

  sortMidsPriorities = () => {
    const {  midsPriorities } = this.state;
    const methods = Object.keys(midsPriorities);

    methods.sort((elemA, elemB) => {
      const modifiedA = elemA.replace(/_/g, " ");
      const modifiedB = elemB.replace(/_/g, " ");

      return modifiedA.localeCompare(modifiedB);
    });
    return methods;
  };

  isDisabledAccount = (accountId) => {
    const { companiesAccounts, controls } = this.state;
    const wlAccounts = companiesAccounts[controls.whiteLabel];
    if (wlAccounts) {
      const account = wlAccounts.find(acc => acc.id === accountId);
      if (account) {
        return !account.payoutEnabled;
      }
    }
    return false;
  };

  handleConfirmPromptModal = () => {
    const { controls, midsPriorities } = this.state;
    this.setState({
      resetCascadingLoading: true
    });
    crudActions.post(`v1/psp/priorities/payout/reset`, controls.data()).then(
      () => {
        Object.keys(midsPriorities).forEach((method) => {
          midsPriorities[method] = midsPriorities[method].map((elem, index) => {
            return Object.assign(elem, {
              priority: ""
            });
          });
        });

        this.setState({
          midsPriorities,
          showAlert: true,
          showPromptModal: false,
          alertType: "success",
          alertMessage: "Cascading is successfully reset.",
          resetCascadingLoading: false,
        });
      }
    ).catch(
      err => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message,
            showPromptModal: false,
            resetCascadingLoading: false,
          });
        }
      }
    );
  };

  handleClosePromptModal = () => {
    this.setState({
      showPromptModal: false,
    });
  };

  onResetClick = () => {
    const { savedData } = this.state;
    this.setState({
      savedData,
      submitTouched: true,
      showPromptModal: true
    });
  };

  render() {
    const { loadingLookups, countries, controls, companies, showPanels, companiesClients,
      loadingSettings, midsPriorities, showAlert, alertType, alertMessage, errorsMap,
      saveTouched, toggleLoader, showPromptModal,
      resetCascadingLoading, savedData, savedCascadingMode } = this.state;

    return (
      <Row flexGrow={ 1 } className='module psp-cascading' vertical='start'>
        <Column flexGrow={ 1 }>
          <PageHeader
            title="Payout Cascading"
            img={ pspPriorityIconSingle }
          />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' style={ { paddingLeft: 15, paddingRight: 15, paddingTop: 15, width: '100%' } }>
              { loadingLookups ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title> FILTER </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  </Panel.Body>
                </Panel>
              ) : (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      FILTER
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> White Label </label>
                          <Select
                            className={ controls.errors.has('whiteLabel') ? 'error-field' : "" }
                            value={ controls.whiteLabel || '' }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onValueChange(value, 'whiteLabel') }
                            options={ companies }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' className="input-column">
                          <label> Merchant </label>
                          <Select
                            className={ controls.errors.has('merchantId') ? 'error-field' : "" }
                            value={ controls.merchantId || '' }
                            required={ true }
                            disabled={ !controls.whiteLabel }
                            clearable={ false }
                            onChange={ (value) => this.onValueChange(value, 'merchantId') }
                            options={ controls.whiteLabel ? companiesClients[controls.whiteLabel] : [] }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Currency </label>
                          <Select
                            className={ controls.errors.has('currency') ? 'error-field' : "" }
                            value={ controls.currency || '' }
                            required={ true }
                            disabled={ !controls.merchantId }
                            clearable={ false }
                            onChange={ (value) => this.onValueChange(value, 'currency') }
                            options={ this.getUniqueItems(controls.whiteLabel, controls.merchantId, "currency") }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Payout Methods </label>
                          <Multiselect
                            isError={ controls.errors.has('payoutMethods') }
                            disabled={ !controls.whiteLabel }
                            selectedItems={ this.mapMethods() }
                            items={ this.getFilteredPayoutMethods() }
                            type={ "payoutMethods" }
                            onChange={ this.onValueChange }/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Country </label>
                            <Select
                              className={ controls.errors.has('country') ? 'error-field' : "" }
                              value={ controls.country || '' }
                              required={ true }
                              disabled={ !controls.whiteLabel }
                              clearable={ false }
                              onChange={ (value) => this.onValueChange(value, 'country') }
                              options={ countries }
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <span/>
                          </Column>
                        </Row>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='end'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <span/>
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' className="input-column">
                            <CustomButton
                              title="Update"
                              type="submit"
                              style={ { outline: "0" } }
                              onClick={ () => this.onUpdateClick() }
                            />
                          </Column>
                        </Row>
                      </Row>
                    </div>
                  </Panel.Body>
                </Panel>
              )}
              { showPanels && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title> PROVIDER PRIORITY </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    { loadingSettings ? (
                      <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                        <Spinner smallContainer={ true } />
                      </div>
                    ) : (
                      <div className="panel-content" style={ { overflow: 'unset' } }>
                        { this.checkPageAccess("PSP_CASCADING_EDIT") && Object.keys(midsPriorities).length !== 0 && (
                          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                            <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                <span/>
                              </Column>
                              <Column flexGrow={ 1 } vertical='start' alignSelf='end' className="input-column">
                                <span/>
                              </Column>
                            </Row>
                            <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='end' className='save-cascading'>
                              <Column flexGrow={ 1 } vertical='start' alignSelf='end' className="input-column">
                                { savedCascadingMode === '2.0' && savedData.country !== 'default'  ? <CustomButton
                                  title="Reset"
                                  type="submit"
                                  className="btn-secondary"
                                  style={ { outline: "0" } }
                                  onClick={ () => this.onResetClick() }
                                /> : <span/>
                                }
                              </Column>
                              <Column flexGrow={ 1 } vertical='start' alignSelf='end' className="input-column">
                                <CustomButton
                                  title="Save"
                                  type="submit"
                                  onClick={ () => this.onSavePriorities() }
                                />
                              </Column>
                            </Row>
                          </Row>
                        )}
                        { midsPriorities && (
                          <Column flexGrow={ 1 } vertical='start' style={ { width: '100%' } }>
                            { this.sortMidsPriorities().map(method => {
                              return (
                                <Column key={ method } flexGrow={ 1 } vertical='start' style={ { marginBottom: "20px" } }>
                                  <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='center' className="icon-div">
                                    {icons[method] ? (
                                      <img src={ icons[method] } alt="method"/>
                                    ) : (
                                      <h3 className="method-name">{ method }</h3>
                                    )}
                                  </Row>
                                  <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end' className="mid-priority-wrapper">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th className="td-priority">
                                            Priority
                                          </th>
                                          <th>
                                            MID name
                                          </th>
                                          <th>
                                            MID ID
                                          </th>
                                          <th>
                                            Provider
                                          </th>
                                          <th className="text-right">
                                            Balance
                                          </th>
                                          <th className="text-right">
                                            Payouts
                                          </th>
                                          <th className="text-right">
                                            Pending
                                          </th>
                                          <th className="text-right">
                                            Rejected
                                          </th>
                                          <th className="text-right">
                                            Success Rate
                                          </th>
                                          <th className="text-right">
                                            MID Status
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        { midsPriorities[method].map((mid) => {
                                          return mid.accountEnabled && (
                                            <tr key={ mid.midId }>
                                              <td className="td-priority">
                                                <Select
                                                  value={ mid.priority }
                                                  required={ true }
                                                  clearable={ false }
                                                  enableSearch={ false }
                                                  className={ errorsMap[mid.midId] && saveTouched ? "error-field" : "" }
                                                  disabled={ !this.checkPageAccess("PSP_CASCADING_EDIT") }
                                                  onChange={ (value) => this.onPriorityValueChange(value, method, mid.midId) }
                                                  options={ this.prioritiesList(midsPriorities[method]) }
                                                />
                                              </td>
                                              <td>
                                                {mid.midName}
                                              </td>
                                              <td>
                                                {mid.midId}
                                              </td>
                                              <td>
                                                {mid.psp}
                                              </td>
                                              <td className="text-right">
                                                {mid.currencySymbol + " " + Number(mid.balance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                              </td>
                                              <td className="text-right">
                                                {mid.payouts}
                                              </td>
                                              <td className="text-right">
                                                {mid.pending}
                                              </td>
                                              <td className="text-right">
                                                {mid.rejected}
                                              </td>
                                              <td className="text-right">
                                                {Number(mid.successRate).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,') + "%"}
                                              </td>
                                              <td className={ `text-${toggleLoader.length && toggleLoader.indexOf(mid.midId) > -1 ? 'center' : 'right'}` }>
                                                {toggleLoader.length && toggleLoader.indexOf(mid.midId) > -1 ?
                                                  <img src={ loadingIcon } alt="loading" />
                                                  : (
                                                    <Toggler
                                                      active={ mid.midEnabled }
                                                      id={ mid.midId }
                                                      className="right-align"
                                                      disabled={ !this.checkPageAccess("MIDS_ON_OFF_STATUS") || this.isDisabledAccount(mid.accountId) }
                                                      onClick={ (value) => this.onTogglerClick(value, method, mid.midId) }
                                                    />
                                                  )}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </Row>
                                </Column>);
                            })}
                          </Column>
                        )}
                      </div>
                    )}
                  </Panel.Body>
                </Panel>
              )}
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}
        <PromptModal
          title="Reset cascading"
          message={ "Are you sure you want to reset all mids for this country? This action can’t be undone" }
          handleConfirm={ this.handleConfirmPromptModal }
          handleClose={ this.handleClosePromptModal }
          isLoading={ resetCascadingLoading }
          showModal={ showPromptModal }
        />

      </Row>
    );
  }
}

export default PayoutCascading;
